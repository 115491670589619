import ImprintView from "@/views/ImprintView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import TermsView from "@/views/TermsView.vue";
import { createRouter, createWebHistory } from "vue-router";
import GiftView from "../views/GiftView.vue";
import NewHabitView from "../views/NewHabitView.vue";

const routes = [
  {
    path: "/",
    redirect: "/habit",
  },
  {
    path: "/habit",
    component: NewHabitView,
  },
  {
    path: "/gift",
    component: GiftView,
  },
  {
    path: "/privacy",
    component: PrivacyView,
  },
  {
    path: "/terms",
    component: TermsView,
  },
  {
    path: "/impressum",
    component: ImprintView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
