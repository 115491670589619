<template>
  <router-view />
</template>

<script setup>
import TelemetryDeck from "@telemetrydeck/sdk";
import { provide } from "vue";

const randomGuid = () => {
  // Check if a GUID is already stored in localStorage
  let guid = localStorage.getItem("vid");

  // If not, generate a new GUID and store it in localStorage
  if (!guid) {
    guid = crypto.randomUUID();

    localStorage.setItem("vid", guid);
  }

  return guid;
};

const td = new TelemetryDeck({
  appID: "1CF8B903-A842-4695-9190-70482A940303",
  clientUser: randomGuid(),
  testMode: process.env.VUE_APP_TEST_MODE == "true",
});

td.signal("Init", { client: "web-app" });

provide("td", td);
</script>

<style>
#app {
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  overflow-x: hidden;
}
</style>
